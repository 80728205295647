import Vue from 'vue'
import App from './App.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueGtag from "vue-gtag";
import VueResizeObserver from "vue-resize-observer";
library.add(faAngleDown)

import VueSocialSharing from 'vue-social-sharing'
import Loading from './components/Loading.vue'

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VueResizeObserver)
Vue.use(VueSocialSharing);

Vue.component('loading', Loading)

Vue.config.productionTip = false


Vue.use(VueGtag, {
  config: { id: "G-FH70TP07Z1" }
});


new Vue({
  render: h => h(App),
}).$mount('#app')

<template>
  <div class="map-viz" >
    <loading v-if="!loaded"></loading>
    <svg id="svg-map" ref="map">
    </svg>
    <div class="map-legend">
        <div class="legend-block">
          <div class="legend-title">Number of organised evacuations</div>
          <svg ref="legend"></svg>
        </div>
        <div class="legend-block">
          <div class="legend-title">Evacuation included</div>
          <div class="legend-colors">
            <div v-for="item in categoryColors.domain()" :key="item" class="color-legend-item">
              <div class="cat-circle" :style="{'background-color': categoryColors(item)}"></div>{{item}}
            </div>
          </div>
        </div>
    </div>
    <div class="tooltip" v-if="selectedItem">
      <div class="country-name">{{selectedItem.data.key.trim()}}</div>
      <div class="flight-info" v-for="cat in selectedItem.data.value.categories" :key="selectedItem.data.key + cat.key">
        <div class="plane-icon" :style="{'background-color': categoryColors(cat.key)}"></div>
        <div class="flight-info-text">
          Organised <span class="value">{{cat.value}}</span> {{cat.value > 1 ?'evacuations' : 'evacuation'}} including <span class="value">{{cat.key}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const d3 = {
  ...require('d3'),
  ...require('d3-selection'),
  ...require('d3-geo'),
  ...require('d3-collection'),
  ...require('d3-geo-projection')

}
export default {
  name: 'EvacuationMapViz',
  props: ["version"],
  data () {
    return {
      loaded:false,
      chartData: [],
      geodata: {},
      selectedItem: null,
      viewBox: '0 0 0 0'
    }
  },
  mounted (){
    var self = this
    window.addEventListener('resize', this.drawMap)

    d3.csv("./data/Dataset_Evacuations.csv", d3.autoType)
      .then(data => {
        data.forEach(entry => {
          if (entry['Nationals'] == "Y" && entry['Dual'] != "Y" && entry['Settled resident'] != "Y"){
            entry.category = "Nationals only"
          } else if (entry['Nationals'] == "Y" && entry['Dual'] == "Y" && entry['Settled resident'] != "Y"){
            entry.category = "Nationals + Dual"
          } else if (entry['Nationals'] == "Y" && entry['Dual'] != "Y" && entry['Settled resident'] == "Y"){
            entry.category = "Nationals + Residents"
          } else if (entry['Nationals'] == "Y" && entry['Dual'] == "Y" && entry['Settled resident'] == "Y"){
            entry.category = "Nationals + Residents + Duals"
          } else {
            entry.category = "Other"
          }
        })
        self.chartData = data
        // Load external data and boot
        d3.json("./data/world2.json",d3.autoType)
          .then(geodata => {
            self.loaded = true
            self.geodata = geodata
            self.initMap()
            self.drawMap()
            self.drawLegend()
          })
      })
      .catch(err => {
        console.log("error loading CSV data")
      })
  },
  computed: {
    // Categories
       categoryColors () {
         return d3.scaleOrdinal()
            .domain(["Nationals only", "Nationals + Dual", "Nationals + Residents", "Nationals + Residents + Duals", "Other"])
            .range(['#4682b4', '#b77ac9', '#ff6d86', '#ffa600', '#cccccc'])
       }
  },
  methods: {
    getCentroid (countryName) {
      countryName = countryName.trim().toLowerCase()
      let country = this.getCoords(countryName)
      if(!country) console.log("cannot find "+ countryName)
      if (countryName == "canada"){
        return this.projection([-112.48662972108532, 60.11694416278435])
      }
      if (countryName == "united states"){
        return this.projection([-103.17, 39.04])
      }
      return country ? this.path.centroid(country) : [0,0]
    },
    getCoords(countryName) {
      countryName = countryName.trim().toLowerCase()

      let country = this.geodata.features.find(e => e.properties.name.trim().toLowerCase() == countryName)
      if(!country) console.log("cannot find "+ countryName)
      return country
    },
    initMap () {
      let width = this.$el.clientWidth
      let height = this.$el.clientWidth * 0.6

      // Map and projection
      this.projection = d3.geoMiller()
        //improve this, fit into container
          .scale(width / 2 / Math.PI)
          .translate([width / 2, height / 2])

      this.svg = d3
        .select(this.$refs.map)
        .attr("width", width)
        .attr("height", height);
        //.attr("viewBox", `0 0 ${width} ${height}`)

      this.svg.append("g")
          .attr("class", "countries")


      this.groupedData = d3.nest()
        .key(d => d['Organising country'])
        .rollup(v => {
          return {
            count: v.length,
            flights: v,
            from: d3.nest()
                .key(d => d['Departure country'])
                .entries(v),
            categories: d3.nest()
              .key(d => d.category)
              .rollup(v => v.length)
              .entries(v)
          }
        })
        .entries(this.chartData);
      
      // BUBBLE
      this.bubbleSize = d3.scaleLinear()
      .domain([1, Math.max(...this.groupedData.map(e => e.value.count))])
      .range([ 5, 22])

    },
    drawMap () {
      let self = this
      let width = this.$el.clientWidth
      let height = this.$el.clientWidth * 0.6

      this.svg
        .attr("width", width)
        .attr("height", height);

      this.projection.fitSize([width, height], this.geodata)
      this.path = d3.geoPath(this.projection);

      // Draw the map
      this.svg.select('.countries')
        .selectAll("path")
        .data(this.geodata.features, d => d.id)
        .join("path")
          .attr("d", this.path )
          .style("fill", '#fafafb')///texture.url())
          .style('stroke', "#dfd6d1")
          .style('stroke-width', 1)
          .style("opacity",1)

      let mouseoverMap = (evt) => {
        let allItems = d3.select(evt.currentTarget.parentNode).selectAll(".country-item").style("opacity", 0.2)
        let node = d3.select(evt.currentTarget)
        node
          .style("opacity", 1)
          .style("fill-opacity", 1)

        let d = node.datum()
        let destCountry = d.key
        const curve = d3.line().curve(d3.curveBasis);
        self.svg
        .selectAll(".link-path")
        .data(d.value.from)
        .enter()
        .append("path")
          .attr("class", "link-path")
          .attr("d", d =>{
            let origCountry = d.key
            let orig = self.getCentroid(origCountry)
            let dest = self.getCentroid(destCountry)
            let mid = [(orig[0] + dest[0])/2 + Math.abs(orig[0] - dest[0])/5, (orig[1] + dest[1])/2]
            return curve([orig, mid, dest])
          })
          .style("fill", "none")
          .style("stroke", d => {
            return self.categoryColors(d.values[0].category)
          })
          .style("stroke-width", d => {
            return d.values.length * 2
          })
          //.style("stroke-linecap", "round")
          .style("stroke-opacity", 0.7)
          .attr("pointer-events", "none")

          allItems.raise()

          self.selectedItem = {
            ...evt.currentTarget.getBoundingClientRect(),
            data: d
          }
      }

      let mouseoutMap= (evt) => {
          d3.select(evt.currentTarget.parentNode).selectAll(".country-item").style("opacity", 1)
          self.svg.selectAll('.link-path').remove()
          self.selectedItem = null
        }

      self.svg.selectAll('.country-item').remove()

      this.groupedData.forEach(element => {
          var pie = d3.pie().value(function(d) {
            return d.value.value;
        })
        var data_ready = pie(d3.entries(element.value.categories))
        
        let pieItem = self.svg
          .append("g")
          .datum(element)
          .attr("class", "country-item")
          .attr("transform", d => {
            return "translate(" +self.getCentroid(element.key)+ ")"
          })
          .on("mouseover", mouseoverMap)
          .on("mouseout", mouseoutMap)

        pieItem
        .selectAll('.slices')
        .data(data_ready)
        .enter()
        .append('path')
        .attr('d', d3.arc()
            .innerRadius(self.bubbleSize(element.value.count) * 0.6)
            .outerRadius(self.bubbleSize(element.value.count))
        )
        .attr('fill', function(d){ 
          return(self.categoryColors(d.data.value.key)) })
        .attr("stroke", "black")
        .style("stroke-width", "0px")
        .style("opacity", 1)
        
        pieItem
          .append("circle")
          .attr("r", self.bubbleSize(element.value.count) * 0.6)
          .attr('fill', '#ffffff')
      })

    },
    drawLegend() {
      // Add legend: circles
      var valuesToShow = [1, 5, 10]
      var xCircle = 24
      var xLabel = 100
      var yCircle = 50
      var height = 50
      var width = 150

      var legendSVG = d3
        .select(this.$refs.legend)
        .attr("width", width)
        .attr("height", height)

      var size = d3.scaleSqrt()
        .domain([1, 10])  // What's in the data, let's say it is percentage
        .range([5, 22])  // Size in pixel

      legendSVG
        .selectAll("legend")
        .data(valuesToShow)
        .enter()
        .append("circle")
          .attr("cx", xCircle)
          .attr("cy", function(d){ return yCircle - size(d) } )
          .attr("r", function(d){ return size(d) })
          .style("fill", "none")
          .attr("stroke", "black")

      // Add legend: segments
      legendSVG
        .selectAll("legend")
        .data(valuesToShow)
        .enter()
        .append("line")
          .attr('x1', function(d){ return xCircle } )
          .attr('x2', xLabel)
          .attr('y1', function(d){ return yCircle - 2*size(d) } )
          .attr('y2', function(d){ return yCircle - 2*size(d) } )
          .attr('stroke', 'black')
          .style('stroke-dasharray', ('2,2'))

      // Add legend: labels
      legendSVG
        .selectAll("legend")
        .data(valuesToShow)
        .enter()
        .append("text")
          .attr('x', xLabel)
          .attr('y', function(d){ return yCircle - 2*size(d) } )
          .text( function(d){ return d } )
          .attr("font-size", 12)
          .attr('alignment-baseline', 'middle')
          .attr('dominant-baseline', 'middle')
    }
  }
}
</script>

<style lang="stylus">
.map-viz
  min-height 500px
  width 100%
  margin-top 40px
  font-family "Barlow"
  font-weight 300
  position relative

  .map-legend
    display flex
    .legend-block
      margin-right 30px
      .legend-title
        font-size 14px
        margin-bottom 5px
    .legend-colors
      display flex
      max-width 360px
      flex-wrap: wrap
    .color-legend-item
      display flex
      align-items center
      font-size 12px
      width 180px
      .cat-circle
        height 10px
        width 10px
        border-radius 2px
        margin-right 5px

  position relative
  .country-item
    cursor pointer
  .tooltip
    position absolute
    border 1px solid #ccc
    background #fff
    border-radius 3px
    padding 8px
    font-size 13px
    top: 0px
    left: 0px
    color #222

    .country-name
      font-size 14px
      font-weight bold
      margin-bottom 6px
      padding-bottom 6px
      border-bottom 2px solid #ccc

    .flight-info
      display flex
      margin-bottom 8px
      align-items center
    .value
      font-weight bold

    .plane-icon
      background-image: url("~@/assets/icons/airplane.svg")
      display inline-block
      width 18px
      height 18px
      background-size: 12px
      background-repeat no-repeat
      background-position center center
      background-color orange
      border-radius 50%
      margin-right 5px


</style>

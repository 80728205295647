<template>
  <div class="passport-viz">
    <loading v-if="!loaded"></loading>
    <svg ref="passChart">
    </svg>
  </div>
</template>

<script>
const d3 = {
  ...require('d3'),
  ...require('d3-selection'),
  ...require('d3-collection')
}
export default {
  name: 'PassportViz',
  data () {
    return {
      loaded: false,
      chartData: []
    }
  },
  props: ['highlighted'],
  mounted (){
    var self = this
    window.addEventListener('resize', this.drawChart)
    d3.csv("./data/passports.csv",d3.autoType)
      .then(data => {
        self.loaded = true
        self.chartData = data
        self.drawChart()
      })
  },
  methods: {
    drawChart () {
      let self = this
      this.width = this.$el.clientWidth
      this.height = this.$el.clientWidth *0.6
      this.margin = ({top: 10, right: 30, bottom: 30, left: 110})

      //Generate random data
      let dates = ['31/12/2019', '29/02/2020', '31/03/2020', '30/04/2020', '31/05/2020']
      this.data = {
        y: "No visa required",
        series: self.chartData.filter(d => d[dates[0]]).map(d => {
          return {
            name: d['Country'],
            values: dates.map(k => d[k])
          }
        }),
        dates: dates.map(d3.utcParse("%d/%m/%Y"))
      };

      let svg = d3
        .select(this.$refs.passChart)
        .attr("width", this.width)
        .attr("height", this.height)

      svg.selectAll('*').remove()

      this.x = d3.scaleUtc()
        .domain(d3.extent(this.data.dates))
        .range([this.margin.left, this.width - this.margin.right])

      this.y = d3.scaleLinear()
        .domain([0, d3.max(this.data.series, d => d3.max(d.values))]).nice()
        .range([this.height - this.margin.bottom, this.margin.top])

      svg.append("g")
        .attr("class", 'x-axis')
        .attr("transform", `translate(0,${this.height - this.margin.bottom})`)
        .call(
          d3.axisBottom(this.x)
          .tickValues(this.data.dates)
          .tickFormat(d3.timeFormat("%d/%m/%Y"))
          .tickSize(-this.height)
        )
        .call(g => {
          g.select(".domain").remove()
        })
        /*.selectAll("text")	
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-65)")*/

      /*svg.append("g")
        .attr("class", 'y-axis')
        .attr("transform", `translate(${this.margin.left},0)`)
        .call(d3.axisLeft(this.y).ticks(6))
        .call(g => {
          g.select(".tick").remove()
          g.selectAll(".tick line").remove()
          g.select(".domain").remove()
        })*/
      

      let line = d3.line()
        .defined(d => !isNaN(d))
        .x((d, i) => this.x(this.data.dates[i]))
        .y(d => this.y(d))

      let coutries = svg.append("g")
        .selectAll("g.country")
        .data(this.data.series)
        .join("g")
        .attr("class", "country")

      let paths = coutries.append("path")
          .attr("fill", "none")
          .attr("stroke", "#4682b4")
          .attr("stroke-width", 2)
          .attr("stroke-opacity", 0.6)
          .attr("stroke-linejoin", "round")
          .attr("stroke-linecap", "round")
          .style("mix-blend-mode", "multiply")
          .attr("d", d => line(d.values))

      coutries.append("text")
            .text(d => d.name.toUpperCase())
            .attr("transform", d =>{
              let countryOffset = 0
              if(d.name == "United States"){
                countryOffset = -7
              }
              if(d.name == "Brazil"){
                countryOffset = 7
              }
              if(d.name == "Japan"){
                countryOffset = 4
              }
              if(d.name == "Germany"){
                countryOffset = -4
              }
              return `translate(${self.x(self.data.dates[0]) - 8},${self.y(d.values[0]) + countryOffset })`
            })
            .attr("text-anchor", "end")
            .attr("dominant-baseline", "middle")
            .attr("fill", "#4682b4")
            .attr("stroke-opacity", 0.6)
            .attr("font-size", 14)
  
      /*coutries.append("text")
            .text(d => d.name.slice(0,3).toUpperCase())
            .attr("transform", d =>{
              return `translate(${self.x(self.data.dates[self.data.dates.length - 1]) + 4},${self.y(d.values[d.values.length - 1])})`
            })*/
  

      svg.call(this.mhover, coutries);

    },
    mhover (svg, path) {
      let self = this
      if ("ontouchstart" in document) svg
          .style("-webkit-tap-highlight-color", "transparent")
          .on("touchmove", moved)
          .on("touchstart", entered)
          .on("touchend", left)
      else svg
          .on("mousemove", moved)
          .on("mouseenter", entered)
          .on("mouseleave", left);

      const dots = svg.append("g")
          .attr("class", "dots")
          .attr("display", "none");

      const countryName = svg.append("text")
          .attr("class", "country-name")
          .attr("font-size", 18)
          .attr("text-anchor", "middle")
          .attr("dominant-baseline", "bottom")
          .attr("display", "none")

      function moved(event) {
        event.preventDefault();
        const pointer = d3.pointer(event, this);

        const xm = self.x.invert(pointer[0]);
        const ym = self.y.invert(pointer[1]);

        const i = d3.bisectCenter(self.data.dates, xm);
        const s = d3.least(self.data.series, d => Math.abs(d.values[i] - ym));
        path
          .selectAll('path')
          .attr("stroke", d => d.name == s.name ? "#4682b4": "#ddd")
        path
          .selectAll('text')
          .attr("fill", d => d.name == s.name ? "#4682b4": "#ddd")
          .filter(d => d.name == s.name)
          .raise();
        //dot.attr("transform", `translate(${self.x(self.data.dates[i])},${self.y(s.values[i])})`)
        
        dots.selectAll("circle")
          .data(s.values)
          .join("circle")
            .attr("r", 4)
            .attr("fill", "#4682b4")
            .attr("transform", (d, cIndex) =>{
              return `translate(${self.x(self.data.dates[cIndex])},${self.y(d)})`
            })
        dots.selectAll("text")
          .data(s.values)
          .join("text")
            .attr("font-size", 15)
            .attr("text-anchor", "middle")
            .attr("dominant-baseline", "middle")
            .text(d => parseInt(d))
            .attr("transform", (d, cIndex) =>{
              return `translate(${self.x(self.data.dates[cIndex])},${self.y(d) - 14})`
            })
            .raise()
            

        /*countryName
          //.attr("transform", `translate(${self.width- self.margin.right + 5},${self.y(s.values[s.values.length - 1])})`)
          .attr("transform", `translate(${self.width/2},${self.y(s.values[i]) - 25 })`)
          .text(s.name)
          */
      }

      function entered() {
        path.selectAll("path").style("mix-blend-mode", null).attr("stroke", "#ddd");
        dots.attr("display", null);
        countryName.attr("display", null)
      }

      function left() {
        path
          .selectAll('path')
          .attr("stroke", "#4682b4")
          .attr("stroke-width", 2)
        path
          .selectAll('text')
          .attr("fill","#4682b4")
     
        //dots.attr("display", "none");
        dots.selectAll("circle").remove()
        dots.selectAll("text").remove()
        countryName.attr("display", "none")

      }
    }
  }
}
</script>

<style lang="stylus">
.passport-viz
  width 100%
  margin-top 40px
  svg
    font-family "Barlow"
    font-weight 300
    .y-axis, .x-axis
      font-family "Barlow"
      font-size 12px
    .x-axis
      line
        stroke: lightgrey
        stroke-opacity: 0.7
        shape-rendering: crispEdges
        stroke-width 1px
        stroke-dasharray 3 3
</style>

<template>
  <div class="mobility-map-viz">
    <loading v-if="!loaded"></loading>
    <div class="text"><span class="value">{{currentActiveCountries}}</span> {{currentActiveCountries == 1 ? 'country' : 'countries'}} had imposed 
      <span class="ban-option" v-for="(q, index) in questions" :key="q" @click="() => currentQuestion = index" :class="{active: currentQuestion == index, first: index==0}">{{q}}</span> by <span class="value">{{formattedTime(selectedTime)}}</span>
    </div>
    <svg id="mobility-timeline" ref="timeline">
      <g class="timeline-container"></g>
      <rect x="0" y="0" height="80" :width="highlightRectSize" :fill="SECOND_COLOR" fill-opacity="0.3"></rect>
      <rect :x="highlightRectSize-1" y="0" height="80" :width="2" :fill="SECOND_COLOR" fill-opacity="1"></rect>
      <circle :cx="highlightRectSize" r="4" cy="42" :fill="SECOND_COLOR" fill-opacity="1" draggable="true"></circle>
    </svg>
    <svg ref="map">
    </svg>
    <div class="tooltip" v-if="selectedItem">
      <div class="country-name">{{selectedItem.country}}</div>
      <div class="content">
        <template v-if="selectedItem.bans[currentQuestion] == 'Yes'">
          Introduced a {{questions[currentQuestion]}} on {{formattedTime(selectedItem.bans_date[currentQuestion])}}
        </template>
        <template v-else-if="selectedItem.bans[currentQuestion] == 'No'">
          Did not introduce a {{questions[currentQuestion]}}
        </template>
        <template v-else>
          No data availabe
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// '#4682b4', '#b77ac9', '#ff6d86', '#ffa600', '#cccccc'
const MAIN_COLOR = '#508991'
const SECOND_COLOR = '#ff6d86'

const d3 = {
  ...require('d3'),
  ...require('d3-selection'),
  ...require('d3-geo'),
  ...require('d3-collection'),
  ...require('d3-time-format'),
  ...require('d3-geo-projection')
}
export default {
  name: 'EvacuationMapViz',
  props: ["version"],
  data () {
    return {
      loaded: false,
      chartData: [],
      questions: ['blanket travel ban', 'selective travel bans'],
      currentQuestion: 0,
      geodata: {},
      selectedTime: null,
      highlightRectSize: 0,
      currentActiveCountries: 0,
      SECOND_COLOR: SECOND_COLOR,
      selectedItem: null,
      tooltipPosX:0,
      tooltipPosY:0
    }
  },
  mounted (){
    var self = this
    window.addEventListener('resize', this.onResize)

    var parseDate = d3.timeParse("%d.%m.%Y")
    d3.csv("./data/exemptions_210205.csv", d3.autoType)
      .then(data => {
        self.chartData = data.map(el =>{
          return {
            country: el['Country'],
            bans:[
              el['Did this country introduce a blanket travel ban (all countries) in 2020?'],
              el['Did this country introduce selective travel bans in 2020?']
            ] ,
            bans_date: [
              parseDate(el['What was the date of entry into force of the blanket travel ban?']),
              parseDate(el['What was the date of entry onto force of the first selective travel ban?'])
            ]

          }
        })
        d3.json("./data/world2.json",d3.autoType)
          .then(geodata => {
            self.loaded = true
            self.geodata = geodata
            self.drawMap()
            self.drawTimeline()
          })
      })
      .catch(err => {
        console.log("error loading CSV data")
      })
  },
  computed: {
    
  },
  methods: {
    formattedTime (t) {
      return d3.timeFormat("%d/%m/%Y")(t)
    },
    drawMap () {
      let width = this.$el.clientWidth
      let height = this.$el.clientWidth * 0.6

      this.svg = d3
        .select(this.$refs.map)
        .attr("width", width)
        .attr("height", height);

      // Map and projection
      var projection = d3.geoMiller()
        //improve this, fit into container
          .scale(width / 2 / Math.PI)
          .translate([width / 2, height / 2])

      this.path = d3.geoPath(projection);

      // Draw the map
      projection.fitSize([width, height], this.geodata)
      //TODO improve update
      
      this.svg.append("g")
          .attr("class", "countries")
      this.updateMap()
    },
    updateMap () {
      let self = this
      let mapData = this.geodata.features.map(f => {
        let c = self.chartData.find(e=> e.country == f.properties.name)
        let active = ''
        if (c && self.selectedTime) {
          let v = c.bans[self.currentQuestion]
          let t = c.bans_date[self.currentQuestion]
          if(v == "Yes" && t < self.selectedTime) {
            active = "Yes"
          } else if(v=="No"){
            active = "No"
          }
        }
        return {
          ...f,
          active : active
        }
      })
      this.currentActiveCountries = mapData.filter(e => e.active == "Yes").length

      //this.svg.selectAll("*").remove()
      let c = this.svg.select('.countries')
        .selectAll("path")
          .data(mapData, d=> d.id)
          .join("path")
          .attr("d", this.path)
          .attr("fill", "#f2f3f3")
          .style('stroke', "#dfd6d1")
          .style('stroke-width', 0.5)
          .style("stroke-opacity",0.5)
          .on("mouseover", evt => {
            let f = d3.select(evt.currentTarget)
            .style('stroke', "#cccccc")
            .style('stroke-width', 1.5)
            .style("stroke-opacity",1)
            self.selectedItem = self.chartData.find(e=> e.country == f.datum().properties.name)
          })
          .on("mouseout", evt => {
            let f = d3.select(evt.currentTarget)
            f.style('stroke', "#dfd6d1")
              .style('stroke-width', 0.5)
              .style("stroke-opacity",0.5)
            self.selectedItem = null
          })

          .transition()
          .duration(500)
          .style("fill", d => {
              if(d.active == "Yes") {
                return MAIN_COLOR
              } else if(d.active=="No"){
                return '#f2f3f3'
              } else{
                return '#f2f3f3'
              }
            })
          
    },
    drawTimeline (){
      let self = this
      let margin = ({top: 0, right: 0, bottom: 20, left: 20})
      let width = this.$el.clientWidth
      let height = this.$refs.timeline.clientHeight - margin.bottom

      this.timeX = d3.scaleUtc().range([margin.left, width - margin.right]);
      var y = d3.scaleLinear().range([margin.top, height]);

      var timeExtent = d3.extent(this.chartData.map(el => el.bans_date[self.currentQuestion]))
      var timeBins = d3.timeDays(d3.timeDay.offset(timeExtent[0],-1),
		                                 d3.timeDay.offset(timeExtent[1],1));
      var binByTime = d3.histogram()
		    .value(function(d) { return d.bans_date[self.currentQuestion] })
		    .thresholds(timeBins);

		  // Bin the data by month
		  var histData = binByTime(this.chartData);

      for(var i = 1; i < histData.length; i++){
        histData[i].length += histData[i-1].length;
      }

      let svg = d3
        .select(this.$refs.timeline)
        .attr("width", width)
        .attr("height", height)


      // Scale the range of the data by setting the domain
		  this.timeX.domain(d3.extent(timeBins))
		  y.domain([0, 170])//d3.max(histData, d => d.length)]);

      svg.selectAll('.x-axis').remove()

      svg.append("g")
        .attr("class", 'x-axis')
        .attr("transform", `translate(0,${height})`)
        .call(
          d3.axisBottom(this.timeX)
          .tickFormat(d3.timeFormat("%d/%m/%Y"))
          .tickSize(10)
        )
        .call(g => {
          g.select(".domain").remove()
        })
      
      svg.select('.timeline-container').selectAll('*').remove()

			svg.select('.timeline-container').selectAll(".bar")
		      .data(histData)
		    .enter().append("rect")
		      .attr("class", "bar")
		      .attr("x", d => self.timeX(d.x0))
		      .attr("width", d => self.timeX(d.x1) - self.timeX(d.x0) - 1)
		      .attr("y", d => height - y(d.length))
		      .attr("height", d=> y(d.length))
          .attr('fill',MAIN_COLOR)

      self.selectedTime = d3.timeParse("%d.%m.%Y")('01.06.2020')
      let updateTime = function (evt) {
        evt.preventDefault();
        const pointer = d3.pointer(evt, this);
        const xm = self.timeX.invert(pointer[0])
        self.selectedTime = xm
      }
      svg.on("click", updateTime)
      svg.on("mousedown", () =>{
        svg.on("mousemove", updateTime)
      })
      svg.on("mouseup", () =>{
        svg.on("mousemove", null)
      })
    },
    onResize () {
      this.drawTimeline()
      this.drawMap()
    }
  },
  watch: {
    selectedTime () {
      this.highlightRectSize = Math.max(this.timeX(this.selectedTime),0)
      this.updateMap()
    },
    currentQuestion () {
      this.drawTimeline()
      this.updateMap()
    }
  }
}
</script>

<style lang="stylus">
.mobility-map-viz
  width 100%
  margin-top 40px
  font-family "Barlow"
  font-weight 300
  position relative
  .text
    font-size 18px
    margin-bottom 10px
    .value
      font-weight bold
    .ban-option
      white-space: nowrap;
      padding 4px 6px
      border 1px solid #ccc
      cursor pointer
      color #ccc
      border-radius 0px 4px 4px 0px
      &.first
        border-radius 4px 0px 0px 4px
      &:hover
        border 1px solid #4682b4
      &.active
        background #fff
        border 1px solid #4682b4
        color #000
  #mobility-timeline
    height 100px
    cursor col-resize

  .tooltip
    position absolute
    top 150px
    left 0
    border 1px solid #ccc
    background #fff
    border-radius 3px
    padding 8px
    font-size 13px
    color #222

    .country-name
      font-size 14px
      font-weight bold
      margin-bottom 6px
      padding-bottom 6px
      border-bottom 2px solid #ccc

</style>

<template>
  <div class="mobility-map-viz">
    <loading v-if="!loaded"></loading>
    <div class="question-selecter">
      <div class="question">Was this category exempted from the travel ban?
        <div class="legend">
          <div class="yes"><span class="circle"></span>Yes</div>
          <div class="no"><span class="circle"></span>No</div>
        </div>
      </div>
      <div class="category-input-group">
        <label class="category-input" :for="q" v-for="(q,index) in questionsOption" :key="q" :class="{'active': currentQuestion== index}">
          <input type="radio" :id="q" :value="index" v-model="currentQuestion">
          {{q}}
        </label>
      </div>
    </div>
    <svg class="map" ref="map">
      <g class="countries"></g>
    </svg>
    <div class="tooltip" v-if="selectedItem">
      <div class="country-name">{{selectedItem.properties.name}}</div>
      <div class="content">
        <template v-if="selectedItem.value == 'Yes'">
          Exempted {{questionsOption[currentQuestion]}} from the travel ban
        </template>
        <template v-else-if="selectedItem.value == 'No'">
          Did not exempt {{questionsOption[currentQuestion]}} from the travel ban
        </template>
        <template v-else>
          No data availabe
        </template>
      </div>
    </div>
  </div>
</template>

<script>
const d3 = {
  ...require('d3'),
  ...require('d3-selection'),
  ...require('d3-geo'),
  ...require('d3-collection'),
  ...require('d3-geo-projection')
}
export default {
  name: 'EvacuationMapViz',
  props: ["version"],
  data () {
    return {
      loaded: false,
      chartData: [],
      geodata: {},
      questions: [],
      currentQuestion: 0,
      selectedItem: null,
      questionsOption: ['nationals of the country', 'residents of the country', 'asylum seekers', 'international students', 'passengers in transit', 'certain categories of workers']
    }
  },
  mounted (){
    var self = this
    window.addEventListener('resize', this.drawMap)
    d3.csv("./data/exemptions_210205.csv", d3.autoType)
      .then(data => {
        self.chartData = data
        self.questions = data.columns.filter(q => q != "Country" && !q.includes('What was the date') && !q.includes('Did'))
        d3.json("./data/world2.json",d3.autoType)
          .then(geodata => {
            self.loaded = true
            self.geodata = geodata
            self.drawMap()
          })
      })
      .catch(err => {
        console.log("error loading CSV data")
      })
  },
  computed: {
    // Categories
       categoryColors () {
         return d3.scaleOrdinal()
            .domain(["Nationals only", "Nationals + Dual", "Nationals + Residents", "Nationals + Residents + Duals", "Other"])
            .range(['#4682b4', '#b77ac9', '#ff6d86', '#ffa600', '#cccccc'])
       }
  },
  methods: {
    drawMap () {
      let width = this.$el.clientWidth
      let height = width * 0.6

      this.svg = d3
        .select(this.$refs.map)
        .attr("width", width)
        .attr("height", height);

      // Map and projection
      var projection = d3.geoMiller()
        //improve this, fit into container
          .scale(width / 2 / Math.PI)
          .translate([width / 2, height / 2])

      this.path = d3.geoPath(projection);

      // Draw the map
      projection.fitSize([width, height], this.geodata)
      //TODO improve update
     
      this.updateMap()
    },
    updateMap () {
      let self = this
      if (!self.chartData) return
      let mapData = this.geodata.features.map(f => {
        let c = self.chartData.find(e=> e['Country'] == f.properties.name)
        return {
          ...f,
          value : c ? c[self.questions[self.currentQuestion]] : ''
        }
      })

      //this.svg.selectAll("*").remove()
      let c = this.svg.select('.countries')
          .selectAll("path")
          .data(mapData, d => d.id)
          .join("path")
          .attr("d", this.path)
          .attr("fill", "#f2f3f3")
          .on("mouseover", evt => {
            let f = d3.select(evt.currentTarget)
            .style('stroke', "#cccccc")
            .style('stroke-width', 1.5)
            .style("stroke-opacity",1)
            self.selectedItem = f.datum()
          })
          .on("mouseout", evt => {
            let f = d3.select(evt.currentTarget)
            f.style('stroke', "#dfd6d1")
              .style('stroke-width', 0.5)
              .style("stroke-opacity",0.5)
            self.selectedItem = null
          })
          .transition()
          .duration(500)
          .style("fill", d => {
            if (d.value) {
              if(d.value == "Yes") {
                return '#00876c'
              } else if (d.value == "No"){
                return '#de425b'
              } else {
                return '#f2f3f3'
              }
            }
            return '#f2f3f3'
          })
          .style('stroke', "#dfd6d1")
          .style('stroke-width', 1)
          .style("stroke-opacity",0.5)

    }


  },
  watch: {
    currentQuestion () {
      this.updateMap()
    }
  }
}
</script>

<style lang="stylus">
.mobility-map-viz
  width 100%
  margin-top 40px
  font-family "Barlow"
  font-weight 300

  position relative
  .question-selecter
    margin-bottom 20px
    .question
      margin-bottom 20px
      font-family "Barlow"
      font-weight 500
      font-size 22px
      display flex
      align-items center

    .legend
      margin-left auto
      display flex
      .circle
        display inline-block
        height 12px
        width 12px
        border-radius 50%
        margin-right 5px
      .yes
        margin-right 20px
        .circle
          background-color #00876c
      .no
        .circle
          background-color #de425b
  .category-input-group
    display flex
    font-size 16px
    flex-wrap: wrap
    .category-input
      white-space: nowrap;
      padding 4px 6px
      border 1px solid #ddd
      border-radius 4px
      margin-right 10px
      margin-bottom 10px
      cursor pointer
      transition: all 0.1s ease
      input
        display none
      &:hover
        border 1px solid #4682b4
      &.active
        background #fff
        border 1px solid #4682b4
  
  .tooltip
    position absolute
    top 150px
    left 0
    border 1px solid #ccc
    background #fff
    border-radius 3px
    padding 8px
    font-size 13px
    color #222

    .country-name
      font-size 14px
      font-weight bold
      margin-bottom 6px
      padding-bottom 6px
      border-bottom 2px solid #ccc

</style>

<template>
  <div id="app">
    <section id="intro">
      <h1>Citizenship, Migration and Mobility in a Pandemic</h1>
      <div class="separator-title"></div>
      <div class="social">
        <ShareNetwork network="facebook" :url="shareUrl" :title="shareTitle">
          <div class="icon fb"></div>
        </ShareNetwork>
        <ShareNetwork network="twitter" :url="shareUrl" :title="shareTitle">
          <div class="icon twitter"></div>
        </ShareNetwork>
        <ShareNetwork network="email" :url="shareUrl" :title="shareTitle">
          <div class="icon email"></div>
        </ShareNetwork>
      </div>
      <div class="desc">
        Every government in the world has introduced restrictions to international movement in an effort to contain the COVID-19 pandemic. Starting in late January 2020, the restrictions initially targeted travellers from China, but quickly expanded to other East Asian countries, Iran, Italy, and soon spread to the entire world. Every government in the world has introduced restrictions to international movement in an effort to contain the COVID-19 pandemic. What has been the impact of these restrictions upon different individuals? And how has this impact varied depending on factors such as citizenship, immigration status, place of residence or employment? 
      </div>
      <div class="video-container">
          <iframe class="video" src="https://www.youtube.com/embed/CqqlPMu4Mus" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <a class="next-section" href="#evacuations">
        <font-awesome-icon icon="angle-down" />
      </a>
    </section>
    <section>
      <h2 id="evacuations">Map of Evacuations</h2>
      <div class="desc">
        As a result of widespread travel bans, many people were left stranded abroad at the start of the pandemic. Some were assisted by the governments of their home countries, who organised evacuations and provided other forms of consular assistance. 
      </div>
      <evacuation-map-viz :highlighted="evacuationHint"></evacuation-map-viz>
      <div class="desc">
        Some states only evacuated their own citizens; others included their families, as well as permanent residents. For example, Canadian evacuation flights from China only included citizens of Canada. A South Korean evacuation from Iran repatriated 74 South Koreans and six Iranian family members; Germany evacuated 102 citizens and 22 residents from the city of Wuhan in March 2020.
      </div>
      <a class="next-section" href="#passports">
        <font-awesome-icon icon="angle-down" />
      </a>
    </section>
    <section >
      <h2 id="passports">Levelling down of global mobility rights</h2>
      <div class="desc">
        Entry restrictions across the world meant that standard travel documents were no longer sufficient to cross international borders. This change was most strongly felt by citizens of countries in the so-called Global North, who might previously have taken for granted the extensive mobility rights associated with their passports. For example, with a Japanese passport, the number of countries you could travel to without the need for a visa dropped 117 to just 11 following the 2020 travel restrictions. For South African passport-holders, the equivalent change was from 63 to 11.
      </div>
      <passport-viz :highlighted="passportHint"></passport-viz>
      <a class="next-section" href="#timeline">
        <font-awesome-icon icon="angle-down" />
      </a>
    </section>
    <section>
      <h2 id="timeline">Timeline</h2>
      <div class="desc">
        Starting in late January 2020, restrictions initially targeted travellers from China, but quickly expanded to other East Asian countries, then to Iran, to Italy, and soon the entire world.
      </div>
      <mobility-map-viz></mobility-map-viz>
      <div class="desc">
        Once introduced, international travel bans generally remained in place throughout 2020. While there have not been different waves of travel restrictions, different professions, or categories of persons, would be exempted at different points in time.
      </div>
      <a class="next-section" href="#exception">
        <font-awesome-icon icon="angle-down" />
      </a>
    </section>
    <section>
      <h2 id="exception">Some mobility continues</h2>
      <div class="desc">
        Not everyone has been affected equally by the travel restrictions. Most states made exceptions for certain categories of people, allowing them to continue to cross their borders during the pandemic. These exceptions typically included citizens abroad, permanent residents and their families. Exceptions were also frequently made for diplomats, medical professionals, and cross-border workers in key sectors, such as agriculture.
      </div>
      <mobility-exceptions-map-viz></mobility-exceptions-map-viz>
      <a class="next-section" href="#resources">
        <font-awesome-icon icon="angle-down" />
      </a>
    </section>
    <section id="resources">
      <h2>Additional resources</h2>
      <ul>
        <li>Piccoli, Lorenzo, Jelena Dzankic, Didier Ruedin, and Timothy Jacob-Owens (2022), <a href="https://journals.sagepub.com/doi/full/10.1177/01979183221118907" target="_blank">Restricting Human Movement During the COVID-19 Pandemic: New Research Avenues in the Study of Mobility, Migration, and Citizenship</a>, International Migration Review.</li>
        <li>Moraru, Madalina (2021), <a href="https://globalcit.eu/wp-content/uploads/2021/02/Moraru_Consular_Brief.pdf">Effective consular protection of unrepresented EU citizens in third countries during the COVID-19 pandemic: law and policy.</a> Background Policy Brief, February 2021.</li>
        <li>
          Piccoli, Lorenzo, Jelena Dzankic, Didier Ruedin (2021), <a href="https://doi.org/10.1371/journal.pone.0248066" target="_blank">Citizenship, Migration and Mobility in a Pandemic (CMMP): A global dataset of COVID-19 restrictions on human movement</a>, PLoS ONE 16(3).
        </li>
        <li>
          Piccoli, Lorenzo, Jelena Dzankic, Andreas Perret, Didier Ruedin, Timothy Jacob-Owens (2020), <a href="https://hdl.handle.net/1814/68359" target="_blank">International travel restrictions in response to the COVID-19 outbreak dataset.</a> EUI Research Data: Robert Schuman Centre for Advanced Studies and nccr – on the move.
          <br/>
          An interactive visualisation is <a href="https://tabsoft.co/3fxs9d0" target="_blank">available online</a>.
        </li>
        <li>
          Piccoli, Lorenzo, Leslie Ader, Paula Hoffmeyer-Zlotnik, Christina Mittmasser, Oliver Pedersen, Aurélie Pont, Frowin Rausis, Petra Sidler (2020), <a href="https://hdl.handle.net/1814/68358" target="_blank">Mobility and border control in response to the COVID-19 outbreak dataset.</a> EUI Research Data: Robert Schuman Centre for Advanced Studies and nccr – on the move.
          <br/>
          An interactive visualisation is <a href="https://tabsoft.co/2YqC5is" target="_blank">available online</a>.
        </li>
      </ul>

      <h2>Media</h2>
      <ul>
        <li>
          Dzankic, Jelena and Lorenzo Piccoli (2020), <a href="https://globalcit.eu/coronavirus-citizenship-infected/" target="_blank">Coronavirus : citizenship infected</a>, GLOBALCIT blog.
        </li>
        <li>
          Dzankic, Jelena and Lorenzo Piccoli (2020), <a href="https://blogs.lse.ac.uk/europpblog/2020/03/17/how-covid-19-is-altering-our-conception-of-citizenship" target="_blank">How COVID-19 is altering our conception of citizenship</a>, LSE Blog.
        </li>
        <li>
          Piccoli, Lorenzo (2020), <a href="https://www.rsi.ch/play/radio/diderot---le-voci-dellattualita-/audio/il-classismo-della-pandemia?id=12974867" target="_blank">Il classismo della pandemia</a>, Radio Svizzera Italiana.
        </li>
        <li>
          Piccoli, Lorenzo (2020), <a href="https://www.washingtonpost.com/politics/2020/04/05/coronavirus-restrictions-movement-may-jeopardize-lives-most-vulnerable/" target="_blank">Coronavirus restrictions on movement may jeopardize the lives of the most vulnerable</a>, Washington Post.
        </li>
        <li>
          Piccoli, Lorenzo and Alex Afonso (2020), <a href="https://www.podbean.com/media/share/pb-g4g72-dc8bbd?fbclid=IwAR1wVUhirhUI9A3l1sYww6Ql2BV1LpBtZK1n-rYmQb8Pgzllb3Qzjp6PF0U" target="_blank">Covid-19 and the sudden death of free movement</a>, The Borders of Equality Podcast.
        </li>
        <li>
          Piccoli, Lorenzo, Jelena Dzankic, Timothy Jacob-Owens, and Didier Ruedin (2021), <a href="https://theloop.ecpr.eu/pandemic-era-travel-has-been-restricted-worldwide-but-not-everyone-has-been-affected-equally/">Pandemic-era travel has been restricted worldwide, but not everyone has been affected equally</a>, The Loop
        </li>
        <li>
          Piccoli, Lorenzo and Joelle Moret (2020), <a href="https://blog.nccr-onthemove.ch/a-sudden-bankruptcy-of-mobility-capital-the-paradoxical-effects-of-pandemics-on-human-movement/" target="_blank">A sudden bankruptcy of mobility capital?: the paradoxical effects of pandemics on human movement</a>, nccr – on the move Blog.
        </li>
      </ul>
    </section>

    <footer>
      <h2>Credits</h2>
      <div class="credit-group">Principal Investigators</div>
        <a href="https://globalcit.eu/team/dzankic-jelena/" target="_blank">Jelena Dzankic</a> (European University Institute)<br/>
        <a href="https://www.lorenzopiccoli.eu/" target="_blank">Lorenzo Piccoli</a> (European University Institute | nccr – on the move)<br/>
      <div class="credit-group">Research Team</div>
        <a href="https://www.linkedin.com/in/luca-bernasconi-aa2a3810a/" target="_blank">Luca Bernasconi</a> (nccr – on the move)<br/>
        <a href="https://me.eui.eu/timothy-jacob-owens/" target="_blank">Timothy Jacob-Owens</a> (European University Institute)<br/>
        <a href="https://cjc.eui.eu/team/moraru-madalina/" target="_blank">Madalina Moraru</a> (European University Institute)<br/>
      <div class="credit-group">Interactive visualisations</div>
        <a href="https://nccr-onthemove.ch/who-is-who/people/?start=p&p_id=1375" target="_blank">Andreas Perret</a> (nccr – on the move)<br/>
        <a href="http://www.danielepezzatini.com/" target="_blank">Daniele Pezzatini</a> (Independent consultant)<br/>
      <div class="credit-group">Financial support</div>
        <a href="https://www.eui.eu/About/Organization/ResearchCouncil" target="_blank">Research Council of the European University Institute</a><br/> 
        <a href="https://nccr-onthemove.ch/" target="_blank">nccr – on the move</a>
        <div class="logos">
          <a href="https://globalcit.eu/" target="_blank"><div class="credit-logo eui"></div></a>
          <a href="https://nccr-onthemove.ch/" target="_blank"><div class="credit-logo nccr"></div></a>
        </div>
      <cookie-law theme="dark-lime"></cookie-law>
    </footer>
  </div>
</template>

<script>
import EvacuationMapViz from './components/EvacuationMapViz'
import PassportViz from './components/PassportViz'
import MobilityMapViz from './components/MobilityMapViz'
import MobilityExceptionsMapViz from './components/MobilityExceptionsMapViz'
import CookieLaw from 'vue-cookie-law'

export default {
  name: 'App',
  components:{EvacuationMapViz, PassportViz, MobilityMapViz, MobilityExceptionsMapViz, CookieLaw},
  data() {
    return {
      passportHint: '',
      evacuationHint: '',
      shareUrl: window.location,
      shareTitle: "Citizenship, Migration and Mobility in a Pandemic"
    }
  }
}
</script>

<style lang="stylus">
@import url('https://fonts.googleapis.com/css2?family=Prata&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;800&display=swap');
html
  scroll-behavior: smooth

body
  margin 0px
  padding 0px

#app
  font-family "Prata", serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  margin 0px
  padding 0px
  font-size 16px
  line-height 1.6em
  color #111111
  h1
    font-family "Barlow", Helvetica, Arial, sans-serif
    font-size 62px
    line-height 1.2em
    font-weight 900
    text-align center
    margin 0px
    @media (max-width: 600px)
      font-size 50px
  h2
    font-family "Barlow", Helvetica, Arial, sans-serif
    font-size 33px
    line-height 1.2em
    font-weight 900
    letter-spacing 0px
    margin 0px
    padding-top 20px
    padding-bottom 20px
  .separator-title
    margin auto
    margin-top 40px
    width 50%
    height 1px
    background #000
    @media (max-width: 600px)
      margin-top 30px
      margin-bottom 30px
  section
    min-height 80vh
    padding 40px 15%
    position relative
    background #fcffff
    @media (max-width: 600px)
      padding 20px 30px

    &:nth-child(1),  &:nth-child(3), &:nth-child(5)
      background rgba(#4682b4, 0.1)

    .video-container
        position: relative;
        width: 100%
        height: 0
        padding-bottom: 56.25%
        margin-bottom 30px
        margin-top 50px
        .video
          position: absolute
          top: 0
          left: 0
          width: 100%
          height: 100%

    .content
      display flex
      justify-content space-between
    .desc
      text-align justify
      margin-top 25px
      margin-bottom 25px
    a
      color #4682b4
      text-decoration none
      font-weight 600
      &:hover
        text-decoration underline
    ul
      margin 0px
      padding 0px
      li
        margin-left 20px
        margin-bottom 1em
    .next-section
      position absolute
      bottom -15px
      height 30px
      width 30px
      display block
      border 2px solid #4682b4
      background #ffffff
      color #4682b4
      z-index: 10
      left: 50%
      transform: translateX(-50%)
      border-radius 50%
      font-size 20px
      display flex
      font-weight 200
      align-items center
      justify-content center

  footer
    font-family "Barlow", Helvetica, Arial, sans-serif
    padding 30px 15%
    min-height 200px
    border-top 3px solid #4682b4
    padding-bottom 60px
    background rgba(#4682b4, 0.1)
    position relative
    @media (max-width: 600px)
      padding 20px 30px
    .credit-group
      font-weight 600
      margin-top  2em
    .logos
      margin-top 1em
      position absolute
      right 15%
      top 105px
      @media (max-width: 960px)
        position relative
        top 0
        right 0
    .credit-logo
      width 180px
      height 100px
      background-position center center
      background-repeat no-repeat
      background-size 180px
      @media (max-width: 960px)
        display inline-block
        margin-right 20px
      &.eui
        background-image url('~@/assets/eui.png')
      &.nccr
        background-image url('~@/assets/nccr.png')
    a, a:visited
      color #4682b4
      text-decoration none
      font-weight 600
      &:hover
        text-decoration underline

.social, .social-footer
  display flex
  width 100px
  margin 20px auto
  justify-content space-between
  .icon
    height 25px
    width 25px
    background-position center center
    background-repeat no-repeat
    background-size cover
    &.fb
      background-image: url("~@/assets/icons/facebook.svg")
    &.twitter
      background-image: url("~@/assets/icons/twitter.svg")
    &.email
      background-image: url("~@/assets/icons/email.svg")


</style>
